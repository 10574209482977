<template>
  <div>
    <div class="topPart">
      <div class="navPart">
        <img src="../assets/a1.jpg" alt="" />
        <ul>
          <li>首页</li>
          <li>关于我们</li>
          <li>服务</li>
          <li>团队</li>
          <li>联系方式</li>
        </ul>
      </div>
    </div>
    <div class="centerPart">
      <ul>
        <li>中科沣图（杭州）科技有限公司</li>
        <li>
          中科沣图科技依托于中科院空天信息创新研究院，入选杭州市”运河英才”计划A类。公司拥有无人机、多光谱传感器以及遥感算法模型领域核心技术，核心技术团队来自中科院，华为，阿里云，海康威视。面向行业应用提供基于遥感大数据的数字化管理服务系统，覆盖从数据采集系统到数据分析与决策服务端到端的解决方案.
        </li>
        <li>查看更多</li>
      </ul>
    </div>
    <div class="aboutPart">
      <div>关于我们</div>
      <div>
        中科沣图科技依托于中科院空天信息创新研究院，入选杭州市”运河英才”计划A类。公司拥有无人机、多光谱传感器以及遥感算法模型领域核心技术，核心技术团队来自中科院，华为，阿里云，海康威视。面向行业应用提供基于遥感大数据的数字化管理服务系统，覆盖从数据采集系统到数据分析与决策服务端到端的解决方案
      </div>
    </div>
    <div class="bottomPart">
      <div>Copyright © 2020.杭州沣图科技有限公司 All rights reserved.</div>
      <a href="https://beian.miit.gov.cn"
        >浙ICP备2020043975号 浙ICP备2020043975号-1</a
      >
      <div>   <img src="../assets/a3.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.topPart {
  height: 110px;
  width: 100%;
  background: #222730;
}
.navPart {
  height: 110px;
  display: flex;
  align-items: center;
  margin: 0 19.792vw;
  justify-content: space-between;
}
.navPart > img {
  height: 100%;
  width: 260px;
}
ul {
  list-style-type: none;
  color: white;
}
.navPart > ul {
  display: flex;
}
.navPart > ul > li {
  margin-left: 55px;
  cursor: pointer;
  transition-duration: 0.5s;
}
.navPart > ul > li:hover {
  color: #00a2c1;
}
.centerPart {
  overflow: hidden;
  width: 100%;
  height: 785px;
  background-image: url(../assets/a2.jpg);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}
.centerPart > ul {
  margin-top: 11.5%;
  width: 930px;
  line-height: 25px;
}
.centerPart > ul > li:nth-child(1) {
  font-size: 3em;
  line-height: 1em;
  font-weight: 600;
}
.centerPart > ul > li:nth-child(2) {
  font-size: 0.85em;
  margin-top: 25px;
}
.centerPart > ul > li:nth-child(3) {
  width: 165px;
  height: 55px;
  border: 2px solid #fff;
  line-height: 55px;
  text-align: center;
  font-size: 1.2em;
  margin-top: 1em;
  border-radius: 0.6em;
  cursor: pointer;
}
.centerPart > ul > li:nth-child(3):hover {
  background: rgba(0, 162, 193, 0.36);
}
.aboutPart {
  margin-top: 135px;
}
.aboutPart > div:nth-child(1) {
  font-size: 2.2em;
  font-weight: 900;
  color: #4f6367;
  margin-left: 20%;
}
.aboutPart > div:nth-child(2) {
  margin: auto;
  width: 910px;
  text-align: center;
  font-size: 0.875em;
  margin-top: 25px;
  color: #4f6367;
}
.bottomPart {
  border-top: 1px solid #05a2c1;
  margin-top: 40px;
  background: #232730;
  width: 100%;
  height: 105px;
  text-align: center;
  padding-top: 20px;
}
.bottomPart > div:nth-child(1) {
  color: #fff;
}
.bottomPart > a {
  display: block;
  margin: 5px 0 5px 0;
  color: #fff;
  text-decoration: 0;
}
.bottomPart > a:hover,
.bottomPart > a:focus {
  color: #e74c3c;
  text-decoration: underline;
}
@media (max-width: 1000px) {
  .aboutPart > div:nth-child(2) {
    margin: auto;
    width: 80%;
    text-align: center;
    font-size: 0.875em;
    margin-top: 30px;
    color: #4f6367;
  }
  .aboutPart > div:nth-child(1) {
    font-size: 2.2em;
    font-weight: 900;
    color: #4f6367;
    margin-left: 5%;
  }
}
</style>
